@tailwind base;
@tailwind components;
@tailwind utilities;

#userDdMenu::after {
  left: 89% !important;
}

.opacity-full {
  opacity: 1 !important;
}

td, th {
  vertical-align: top;
}

.row-spacer {
  transition: height 500ms linear;
}

.height-to-x {
  height: 20px;
}

.oi-put-bar {
  fill: red;
  opacity: 0.86;
}
.oi-call-bar {
  fill: rgb(0, 174, 0);
}

.oi-bar-itm {
  opacity: 0.2;
}


@layer components {

  .page-title {
    @apply text-center;
    @apply text-3xl;
    @apply font-thin;
    @apply pb-6;
  }

  .view-title {
    @apply text-center;
    @apply text-xl;
    @apply font-thin;
  }

  .journal-title {
    @apply text-center;
    @apply text-2xl;
    @apply font-extralight;
    @apply pb-1;
  }

  .body-menu-button {
    @apply rounded;
    @apply px-4;
    @apply py-1.5;
    @apply w-52;
  }

  .th-tl {
    @apply px-1;
    @apply whitespace-nowrap;
    @apply text-left;
  }

  .th-tr {
    @apply px-1;
    @apply whitespace-nowrap;
    @apply text-right;
  }

  .th-tc {
    @apply px-1;
    @apply whitespace-nowrap;
    @apply text-center;
  }

  .col-1-w  { min-width: 5rem; }
  .col-2-w  { min-width: 10rem; }
  .col-2a-w  { min-width: 11rem; }
  .col-3-w  { min-width: 4rem; }
  .col-3a-w  { min-width: 3rem; }
  .col-4-w  { min-width: 4rem; }
  .col-5-w  { min-width: 5rem; }
  .col-6-w  { min-width: 5rem; }
  .col-7-w  { min-width: 5rem; }
  .col-8-w  { min-width: 5rem; }
  .col-9-w  { min-width: 5rem; }
  .col-10-w { min-width: 5rem; }
  .col-11-w { min-width: 6rem; }
  .col-12-w { min-width: 6rem; }

  .tiers-4-lh { line-height: 1.25rem; }
  .tiers-5-lh { line-height: 1.1rem; }
  .tiers-6-lh { line-height: 0.87rem; }
  .tiers-7-lh { line-height: 0.8rem; }

  .minw1 { min-width: 1rem; }
  .minw1-5 { min-width: 1.5rem; }
  .minw2-3 { min-width: 2.3rem; }
  .minw3 { min-width: 3rem; }
  .minw4 { min-width: 4rem; }
  .minw5 { min-width: 5rem; }
  .minw6 { min-width: 6rem; }
  .minw7 { min-width: 7rem; }
  .minw10 { min-width: 10rem; }

  .mt2px { margin-top: 2px; }
  .mt3px { margin-top: 3px; }

  .action-icons {
    @apply cursor-pointer;
    @apply text-lg;
    @apply mx-0.5;
  }

  .action-icons-md {
    @apply action-icons;
    font-size: 1.37rem;
  }

  .action-icons-xl {
    @apply action-icons;
    font-size: 1.66rem;
    stroke-width: 1.2;
  }

  .check-circle-sz {
    font-size: 1.17rem;
  }

  .input-base {
    @apply w-16;
    @apply dark:bg-dark-grey;
    @apply border-0;
    @apply border-b;
    @apply border-b-orange;
    @apply p-0;
    @apply pl-1;
  }

  .input-narrow {
    @apply input-base;
    @apply w-12;
  }

  .input-wide {
    @apply input-base;
    @apply w-20;
  }

  .input-full-wide {
    @apply input-base;
    @apply dark:bg-very-dark-grey;
    @apply w-full;
  }

  .pos-font {
    @apply font-light;
    @apply leading-tight;
  }

  .journal-input-full-wide {
    @apply input-base;
    @apply dark:bg-[#ffffff10];
    @apply w-full;
    @apply mr-2;
  }

  .dnd-dd-text {
    @apply text-base;
    @apply font-normal;
  }

  .tasty-data-div {
    @apply pl-3;
    @apply text-right;
  }

  .tasty-data-header-div {
    @apply tasty-data-div;
    @apply font-bold;
  }

  .tasty-data-body-div {
    @apply tasty-data-div;
    @apply font-thin;
  }

  .max-content-width {
    @apply max-w-6xl;
  }

  .max-content-width-tasty {
    /* @apply max-w-5xl; */
    max-width: 960px;
  }

  .text-2xs {
    font-size: 0.6rem;
    line-height: 1rem;
  }

}
